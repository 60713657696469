import { AfterViewChecked, AfterViewInit, Component, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService, CredentialsService } from '@app/auth';
import { TRANSLOCO_SCOPE, TranslocoService } from '@jsverse/transloco';
import { RealEstateService } from '@app/core/services/realEstate/real-estate.service';
import { BaseLicenseService } from '@app/core/services/billing/base-license/base-license.service';
import { MatDialog } from '@angular/material/dialog';
import { VideoDetailsService } from '@app/core/services/video-details.service';
import { Meta } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WithdrawalProductType } from '@generated/generatedEntities';
import { BillingModalService } from '@app/core/services/billing/billing/billing-modal/billing-modal.service';
import { Router } from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'kv-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: ['products', 'global', 'register', 'prices'],
    },
  ],
})
export class LandingPageComponent implements OnInit, AfterViewInit, AfterViewChecked {
  public credentialsService = inject(CredentialsService);
  private baseLicenseService = inject(BaseLicenseService);
  private realEstateService = inject(RealEstateService);
  private authenticationService = inject(AuthenticationService);
  private dialog = inject(MatDialog);
  private videoDetailService = inject(VideoDetailsService);
  private metaTagService = inject(Meta);
  private translocoService = inject(TranslocoService);
  private billingModalService = inject(BillingModalService);
  private router = inject(Router);
  // authenticated: any;
  isAuthenticated: boolean = false;
  showBuildingCosts: boolean = true;
  hasInsuranceValues: boolean = false;
  isAdmin: boolean = false;
  hasBuildingCosts: boolean = false;
  hasPlanningFondations: boolean = false;
  hasLcaCosts: boolean = false;
  hasOperationalCosts: boolean = false;
  hasRebuildCosts: boolean = false;
  hasLccCosts: boolean = false;

  /**
   * Used for special route /lcc -> so we don't remove the route. for analytics.
   */
  scrolledToLcc: boolean = false;
  scrolledToLca: boolean = false;

  /**
   * this is used with the lca route so we can scroll to the lca section on route.
   */
  @ViewChild('sectionId_lcc') lccSection?: ElementRef;
  @ViewChild('sectionId_lca') lcaSection?: ElementRef;

  langKey: string = '';

  constructor() {
    this.realEstateService
      .getCounterNumber()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (value) => {
          //console.log(`counter value integrate for animated digit: `, value);
          // if (value.cnt > 0) {
          //   // this.animatedDigit = value.cnt;
          // } else {
          //   // this.animatedDigit = 9266;
          // }
        },
      });
  }

  ngOnInit(): void {
    this.updateLangKey();
    this.initializeData();
  }

  ngAfterViewInit() {}

  updateLangKey() {
    this.translocoService.langChanges$.pipe(untilDestroyed(this)).subscribe({
      next: (res) => {
        console.log(res);
        this.langKey = res;
      },
      error: (error) => {},
    });
  }

  ngAfterViewChecked() {
    if (this.router.url.includes('lcc')) {
      if (this.lccSection && !this.scrolledToLcc) {
        this.lccSection?.nativeElement.scrollIntoView({ block: 'center', inline: 'center', behavior: 'instant' });
        this.scrolledToLcc = true;
      }
    }
    if (this.router.url.includes('lca')) {
      if (this.lcaSection && !this.scrolledToLca) {
        this.lcaSection?.nativeElement.scrollIntoView({ block: 'center', inline: 'center', behavior: 'instant' });
        this.scrolledToLca = true;
      }
    }
  }

  scroll(el: HTMLElement) {
    //console.log('el value', el);
    el.scrollIntoView({ block: 'center', inline: 'center', behavior: 'smooth' });
  }

  updateRightFlags() {
    this.showBuildingCosts = this.authenticationService.hasAnyModule('BUILDING_COSTS_AND_DATES');
    this.hasInsuranceValues = this.authenticationService.hasAnyModule('BUILDING_INSURANCE_VALUES');
    this.hasBuildingCosts = this.authenticationService.hasAllModule('BUILDING_COSTS_AND_DATES');
    this.hasOperationalCosts = this.authenticationService.hasAnyModule('OPERATING_COSTS');
    this.hasRebuildCosts = this.authenticationService.hasAnyModule('REBUILD_COSTS');
    this.hasLccCosts = this.authenticationService.hasAnyModule('LCC');
    this.hasLcaCosts = this.authenticationService.hasAllModule('LCA');
    this.hasPlanningFondations = this.authenticationService.hasAnyModule([
      'BUILDING_COSTS_AND_DATES',
      'REBUILD_COSTS',
      'OPERATING_COSTS',
      'LCC',
      'LCA',
      'EFFORT_PLANNER',
    ]);
  }

  initializeData() {
    this.invokeMetaTags();
    this.isAuthenticated = this.credentialsService.isAuthenticated();
    if (this.isAuthenticated) {
      this.updateRightFlags();
    }
    this.authenticationService
      .getAuthenticationState()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (value) => {
          this.isAuthenticated = this.authenticationService.isAuthenticated();
          if (value != null) {
            this.updateRightFlags();
          }
        },
      });
  }

  openBillingModal(wtp: WithdrawalProductType) {
    if (this.isAuthenticated) {
      console.warn(wtp);
      this.billingModalService.moduleNames$.next([wtp]);
      this.billingModalService.openBillingModal([wtp]);
    } else {
      this.router.navigate(['/register']);
    }
  }

  openVideoDialog(): void {
    this.videoDetailService.openVideoDialog();
    // const dialogRef = this.dialog.open(VideoDialogComponent, {
    //   width: '100%',
    //   // data: { name: this.name, color: this.color }

    //   panelClass: ['dimension-modalbox', 'video-container'],
    //   closeOnNavigation: true,
    // });
  }

  openVideoPom() {
    this.videoDetailService.getTeaserVideoPom();
  }

  invokeMetaTags() {
    //document.title = 'Baukostenschätzung & Baukostenberechnung Schweiz | Für Architekten, Bauherren & Co.' // service isnt working as expected surrently
    this.metaTagService.addTags([
      // General SEO
      { name: 'title', content: 'Baukostenschätzung & Baukostenberechnung Schweiz | Für Architekten, Bauherren & Co.' },
      {
        name: 'description',
        content:
          'Die innovative Lösung zur Berechnung von Baukosten: Neubau-, Betriebs- und Umbaukosten. Jetzt 7 Tage gratis testen!',
      },
      { name: 'Keywords', content: 'Neubau Kosten berechnen' },
      // OpenGraph
      { property: 'og:type', content: 'website' },
      { property: 'og:title', content: 'kennwerte' },
      {
        property: 'og:description',
        content:
          'Die innovative Lösung zur Berechnung von Baukosten: Neubau-, Betriebs- und Umbaukosten. Jetzt 7 Tage gratis testen!',
      },
      // Twitter
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:site', content: '@kennwerte' },
      { name: 'twitter:title', content: 'kennwerte' },
      {
        name: 'twitter:description',
        content:
          'Die innovative Lösung zur Berechnung von Baukosten: Neubau-, Betriebs- und Umbaukosten. Jetzt 7 Tage gratis testen!',
      },
    ]);
  }
}
